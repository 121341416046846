import React from 'react';
import PropTypes from 'prop-types';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';

class AbaRules extends React.Component {
  render() {
    const {
      abaRules, toggleRuleBookmark, favorites, loading,
    } = this.props;
    return (
      <div className="aba-rules">
        <div className="aba-rule-list">
          {
            loading ? (
              <div className="loader">
                <ClipLoader color="rgba(0, 14, 87, 1)" />
                <div className="name">Loading</div>
              </div>
            ) : abaRules.map((rule, index) => (
              <div className="results" key={rule.id}>
                { index === 0 && <div className="title" /> }
                <a
                  className="result"
                  key={rule.rule_id}
                  href={`/aba_model_rules/${rule.rule_id}`}
                >
                  <div className="result-number">
                    { rule.number }
                  </div>
                  {favorites.rules && favorites.rules.length > 0 && favorites.rules.some(
                    (favoriteRule) => favoriteRule?.id === rule.rule_id,
                  ) ? (
                    <FaBookmark
                      onClick={(e) => toggleRuleBookmark(e, rule)}
                    />
                    ) : (
                      <FaRegBookmark
                        className="initially-hidden"
                        onClick={(e) => toggleRuleBookmark(e, rule)}
                      />
                    )}
                </a>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

AbaRules.propTypes = {
  abaRules: PropTypes.array.isRequired,
  toggleRuleBookmark: PropTypes.func.isRequired,
  favorites: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AbaRules;
