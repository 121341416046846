import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import AbaRules from './src/AbaRules';
import FederalRules from './src/FederalRules';
import FederalRulesNavigator from './src/FederalRulesNavigator';
import StateRules from './src/StateRules';
import StateRulesNavigator from './src/StateRulesNavigator';
import DistrictRules from './src/DistrictRules';
import RegulatoryRules from './src/RegulatoryRules';
import RegulatoryRulesNavigator from './src/RegulatoryRulesNavigator';
import api from '../../services/api';
import DistrictRulesNavigator from './src/DistrictRulesNavigator';
import AAARules from './src/AAARules';
import InternationalRules from './src/InternationalRules';

export default function Rules({ favorites: userFavorites, allStates }) {
  const searchParams = new URLSearchParams(window.location.search);
  const ruleCategories = ['Federal', 'District courts', 'State courts', 'Regulatory', 'ABA'];
  const selectedRules = searchParams.get('selectedRules') || 'Federal';

  const [federalFilter, setFederalFilter] = useState('All');
  const [regulatoryFilter, setRegulatoryFilter] = useState('All');
  const [stateFilter, setStateFilter] = useState(searchParams.get('state') || '');
  const [stateRuleCategoryFilter, setStateRuleCategoryFilter] = useState('All');
  const [districtStateFilter, setDistrictStateFilter] = useState(searchParams.get('districtState') || '');
  const [districtFilter, setDistrictFilter] = useState(searchParams.get('district') || 'All');
  const [favorites, setFavorites] = useState(userFavorites || {});
  const [abaRules, setAbaRules] = useState([]);
  const [regulatoryAgencies, setRegulatoryAgencies] = useState([]);
  const [states, setStates] = useState([]);
  const [districtStates, setDistrictStates] = useState([]);
  const [federalRuleCategories, setFederalRuleCategories] = useState([]);
  const [aaaRules, setAaaRules] = useState([]);
  const [internationalRules, setInternationalRules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mobileOpened, setMobileOpened] = useState(false);

  useEffect(() => {
    switch (selectedRules) {
      case 'Federal':
        api.rules.federal.get().then((res) => setFederalRuleCategories(res.message));
        break;
      case 'AAA':
        api.rules.aaa.get().then((res) => setAaaRules(res.message));
        break;
      case 'International':
        api.rules.international.get().then((res) => setInternationalRules(res.message));
        break;
      case 'ABA':
        api.rules.aba.get().then((res) => setAbaRules(res.message));
        break;
      case 'State courts':
        api.rules.state.get(stateFilter).then((res) => setStates(res.message));
        break;
      case 'District courts':
        api.rules.district.get(districtStateFilter).then((res) => setDistrictStates(res.message));
        break;
      case 'Regulatory':
        api.rules.regulatory.get().then((res) => setRegulatoryAgencies(res.message));
        break;
      default:
        break;
    }

    setLoading(false);
  }, []);

  const filterStates = () => (stateFilter === '' ? null : states.filter((state) => state.name === stateFilter));

  const validateDistrictFilter = (filter) => {
    if (districtStateFilter === '') return;
    if (filter === '') {
      setDistrictFilter(filter);
      return;
    }

    const districtParams = new URLSearchParams(window.location.search);
    districtParams.set('district', filter);
    window.history.pushState({}, '', `/rules?${districtParams.toString()}`);
    setDistrictFilter(filter);
  };

  const validateStateFilter = (filter) => {
    if (filter === '') {
      setStateFilter(filter);
      return;
    }

    const stateParams = new URLSearchParams(window.location.search);
    stateParams.set('state', filter);
    window.history.pushState({}, '', `/rules?${stateParams.toString()}`);
    setStateFilter(filter);
    setStateRuleCategoryFilter('All');

    if (!states.some((state) => state.name === filter) && allStates.some((state) => state.name === filter)) {
      api.rules.state.get(filter).then((res) => {
        if (res.message[0] === undefined) return;
        setStates([...states, res.message[0]]);
      });
    }
  };

  const validateDistrictStateFilter = (filter) => {
    if (filter === '') {
      setDistrictStateFilter(filter);
      return;
    }

    const districtParams = new URLSearchParams(window.location.search);
    districtParams.set('districtState', filter);
    window.history.pushState({}, '', `/rules?${districtParams.toString()}`);
    setDistrictStateFilter(filter);

    if (!districtStates.some((state) => state.name === filter) && allStates.some((state) => state.name === filter)) {
      api.rules.district.get(filter).then((res) => {
        if (res.message[0] === undefined) return;
        setDistrictStates([...districtStates, res.message[0]]);
      });
    }
  };

  const selectRules = (e) => {
    document.location = `rules?selectedRules=${e.target.value}`;
  };

  const toggleRuleBookmark = (e, rule) => {
    e.stopPropagation();
    e.preventDefault();

    api.rules.favorite(rule.rule_id).then((res) => {
      console.log(res);
      if (res.status === 'OK') api.favorites.get().then((result) => setFavorites(result.favorites));
    });
  };

  const renderSelectedRules = () => {
    const selectedState = filterStates();
    switch (selectedRules) {
      case 'Federal':
        return (
          <FederalRules
            federalRuleCategories={federalRuleCategories.filter(
              (category) => (federalFilter === 'All' ? true : category.name === federalFilter),
            )}
            toggleRuleBookmark={toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      case 'District courts':
        return (
          <DistrictRules
            districtStates={districtStateFilter === '' ? null : districtStates.filter(
              (districtState) => districtState.name === districtStateFilter,
            )}
            districtFilter={districtFilter}
            toggleRuleBookmark={toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      case 'State courts':
        return (
          <StateRules
            states={selectedState}
            stateRuleCategories={selectedState && selectedState[0]
              ? selectedState[0].stateRuleCategories.filter((category) => (stateRuleCategoryFilter === 'All' ? true : category.name === stateRuleCategoryFilter))
              : []}
            toggleRuleBookmark={toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      case 'Regulatory':
        return (
          <RegulatoryRules
            regulatoryAgencies={regulatoryAgencies.filter(
              (agency) => (regulatoryFilter === 'All' ? true : agency.name === regulatoryFilter),
            )}
            toggleRuleBookmark={toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      case 'ABA':
        return (
          <AbaRules
            abaRules={abaRules}
            toggleRuleBookmark={toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      case 'AAA':
        return (
          <AAARules
            aaaRules={aaaRules}
            toggleRuleBookmark={toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      case 'International':
        return (
          <InternationalRules
            internationalRules={internationalRules}
            toggleRuleBookmark={toggleRuleBookmark}
            favorites={favorites}
            loading={loading}
          />
        );
      default:
        return null;
    }
  };

  const renderSelectedRulesNavigator = () => {
    const selectedState = filterStates();
    switch (selectedRules) {
      case 'Federal':
        return (
          <FederalRulesNavigator
            federalRuleCategories={federalRuleCategories}
            setFederalFilter={(filter) => setFederalFilter(filter)}
            federalFilter={federalFilter}
          />
        );
      case 'District courts':
        return (
          <DistrictRulesNavigator
            districtStates={districtStates}
            setDistrictStateFilter={validateDistrictStateFilter}
            selectDistrict={validateDistrictFilter}
            allStates={allStates}
            districtStateFilter={districtStateFilter}
          />
        );
      case 'State courts':
        return (
          <StateRulesNavigator
            allStates={allStates}
            states={selectedState}
            setStateFilter={validateStateFilter}
            setStateRuleCategoryFilter={(filter) => setStateRuleCategoryFilter(filter)}
            stateRuleCategoryFilter={stateRuleCategoryFilter}
          />
        );
      case 'Regulatory':
        return (
          <RegulatoryRulesNavigator
            regulatoryAgencies={regulatoryAgencies}
            setRegulatoryFilter={(filter) => setRegulatoryFilter(filter)}
            regulatoryFilter={regulatoryFilter}
          />
        );
      case 'ABA':
      case 'AAA':
      case 'International':
        return null;
      default:
        return null;
    }
  };

  const mobileHeaderToggle = () => {
    // disable page scroll if mobile header opened
    if (!mobileOpened) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
    setMobileOpened(!mobileOpened);
  };

  return (
    <div className="react-rules">
      <div className="mobile-search-open" onClick={mobileHeaderToggle} />
      <div className={`left ${mobileOpened ? 'opened' : ''}`}>
        <div className="title hide-mobile">
          Rules
        </div>
        <div className="rules-description">
          We&apos;ve identified and included the rules impacting ediscovery for all federal,
          state, and district courts as well as regulatory agencies, the ABA, AAA and International.
          Court guidelines are also included. We&apos;ve tagged rules by issue so that you can
          find what you need in just a few clicks.
        </div>
        <div className="mobile-rules-select">
          { ruleCategories.map((category) => (
            <a
              key={category}
              className={`mobile-category ${selectedRules === category ? 'selected' : ''}`}
              href={`/rules?selectedRules=${category}`}
            >
              { category }
            </a>
          ))}
        </div>
        <div className="rules-select">
          <select onChange={selectRules} value={selectedRules}>
            <option value="Federal">Federal</option>
            <option value="District courts">District courts</option>
            <option value="State courts">State courts</option>
            <option value="Regulatory">Regulatory</option>
            <option value="ABA">ABA</option>
            <option value="AAA">American Arbitration Association (AAA)</option>
            <option value="International">International</option>
          </select>
        </div>
        {renderSelectedRulesNavigator()}
      </div>
      <div className="right">
        {renderSelectedRules()}
      </div>
      <ReactTooltip effect="solid" html delayHide={1000} />
    </div>
  );
}
