import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { GoSearch } from 'react-icons/go';
import { ClipLoader } from 'react-spinners';
import TagList from '../../tags/TagList';

export default function RegulatoryRules({
  regulatoryAgencies, toggleRuleBookmark, favorites, loading,
}) {
  const categoriesCount = regulatoryAgencies.reduce((a, b) => a + b.regulatory_rules.length, 0);
  const [issuesQuery, setIssuesQuery] = useState('');

  const isMatchQuery = (rule) => {
    if (!rule || !rule?.published_revision) return false;
    return (issuesQuery === '' || rule.published_revision.tag_list.find((tag) => tag.toLowerCase().includes(issuesQuery.toLowerCase())))
    && !Array.isArray(rule.published_revision);
  };

  if (loading) {
    <div className="regulatory-rules">
      <div className="loader">
        <ClipLoader color="rgba(0, 14, 87, 1)" />
        <div className="name">Loading</div>
      </div>
    </div>;
  }

  const renderSearch = () => (
    <div className="case-search">
      <input type="text" placeholder="Search Issues, try: Sanctions" onChange={(e) => setIssuesQuery(e.target.value)} value={issuesQuery} />
      <GoSearch />
      <span style={{ flex: 1 }} />
    </div>
  );

  return (
    <div className="regulatory-rules">
      { regulatoryAgencies.map((agency, index) => (
        <div className="regulatory-rule-list" key={agency.name}>
          {
            (regulatoryAgencies.length > 1 && index === 0) ? (
              <div className="title">
                All
                <span className="selected-category-count">{categoriesCount}</span>
                { renderSearch() }
              </div>
            ) : <div />
          }
          {
            regulatoryAgencies.length === 1 && (
            <>
              <div className="title">
                {agency.name}
                <span className="selected-category-count">{categoriesCount}</span>
                { renderSearch() }
              </div>
              <div className="description">
                {agency.description || ''}
              </div>
            </>
            )
          }
          <div className="results">
            { agency.regulatory_rules.map((rule) => isMatchQuery(rule) && (
              <a
                className="result"
                key={rule.id}
                href={`/regulatory_rules/${rule.id}`}
              >
                <div className="top">
                  <div className="result-name">
                    { rule.published_revision.number }
                  </div>
                  {favorites.rules && favorites.rules.length > 0 && favorites.rules.some(
                    (favoriteRule) => favoriteRule?.id === rule.id,
                  ) ? (
                    <FaBookmark onClick={(e) => toggleRuleBookmark(e, rule.published_revision)} />
                    ) : (
                      <FaRegBookmark
                        className="initially-hidden"
                        onClick={(e) => toggleRuleBookmark(e, rule.published_revision)}
                      />
                    )}
                </div>
                <TagList className="result-tags" tags={rule.published_revision.tag_list} />
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

RegulatoryRules.propTypes = {
  regulatoryAgencies: PropTypes.array.isRequired,
  toggleRuleBookmark: PropTypes.func.isRequired,
  favorites: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
