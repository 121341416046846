import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { GoSearch } from 'react-icons/go';
import { ClipLoader } from 'react-spinners';
import TagList from '../../tags/TagList';

export default function StateRules({
  states, stateRuleCategories, toggleRuleBookmark, favorites, loading,
}) {
  const categoriesCount = stateRuleCategories.reduce((a, b) => a + b.rules.length, 0);
  const [issuesQuery, setIssuesQuery] = useState('');

  const isMatchQuery = (rule) => issuesQuery === '' || rule.tag_list.find((tag) => tag.toLowerCase().includes(issuesQuery.toLowerCase()));

  if (loading) {
    <div className="state-rules">
      <div className="loader">
        <ClipLoader color="rgba(0, 14, 87, 1)" />
        <div className="name">Loading</div>
      </div>
    </div>;
  }

  if (states === null) {
    <div className="state-rules">
      <div className="select-state">
        <div className="empty-description">
          <span>There are no state court rules displayed.</span>
          <span>Select a filter on the left to start.</span>
        </div>
        <div className="empty-image" />
      </div>
    </div>;
  }

  return (
    <div className="state-rules">
      {states?.map((state) => (
        <div className="state-rule-list" key={state.name}>
          {
            stateRuleCategories.length > 1 && (
            <div className="title">
              All
              <span className="selected-category-count">{categoriesCount}</span>
              <div className="case-search">
                <input type="text" placeholder="Search Issues, try: Criminal" onChange={(e) => setIssuesQuery(e.target.value)} value={issuesQuery} />
                <GoSearch />
                <span style={{ flex: 1 }} />
              </div>
            </div>
            )
          }
          <div className="title">{ state.name }</div>
          <div className="summary">{ state.description }</div>
          { stateRuleCategories.map((category) => (
            <div className="state-rule-list" key={category.name}>
              <div className="title">{ category.name }</div>
              <div className="results">
                {category.rules.map((rule) => (
                  isMatchQuery(rule) && (
                    <a
                      className="result"
                      key={rule.rule_id}
                      href={`/state_rules/${rule.rule_id}`}
                    >
                      <div className="top">
                        <div className="result-number">
                          <div style={rule.title
                            ? { flex: 'none', maxWidth: '50%' } : { maxWidth: '100%' }}
                          >
                            { rule.number + (rule.title && ' - ') }
                          </div>
                          &nbsp;
                          <div style={{ fontWeight: 400 }}>{rule.title}</div>
                        </div>
                        {favorites.rules && favorites.rules.length > 0 && favorites.rules.some(
                          (favoriteRule) => favoriteRule?.id === rule.rule_id,
                        ) ? (
                          <FaBookmark onClick={(e) => toggleRuleBookmark(e, rule)} />
                          ) : (
                            <FaRegBookmark
                              className="initially-hidden"
                              onClick={(e) => toggleRuleBookmark(e, rule)}
                            />
                          )}
                      </div>
                      <TagList className="result-tags" tags={rule.tag_list} />
                    </a>
                  )
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

StateRules.propTypes = {
  states: PropTypes.array,
  stateRuleCategories: PropTypes.array,
  toggleRuleBookmark: PropTypes.func.isRequired,
  favorites: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

StateRules.defaultProps = {
  states: null,
  stateRuleCategories: [],
};
