import React from 'react';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import TagList from '../../tags/TagList';

export default function InternationalRules({
  internationalRules, toggleRuleBookmark, favorites, loading,
}) {
  if (loading) {
    return (
      <div className="aba-rules">
        <div className="aba-rule-list">
          <div className="loader">
            <ClipLoader color="rgba(0, 14, 87, 1)" />
            <div className="name">Loading</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="federal-rules" style={{ marginTop: '2rem' }}>
      <div className="federal-rule-list">
        <div className="results">
          {internationalRules.map((rule) => (
            <a
              className="result"
              key={rule.rule_id}
              href={`/international_rules/${rule.rule_id}`}
            >
              <div className="top">
                <div className="result-number">
                  <div style={rule.title
                    ? { flex: 'none', maxWidth: '50%' } : { maxWidth: '100%' }}
                  >
                    {rule.number + (rule.title && ' - ')}
                  </div>
                  &nbsp;
                  <div style={{ fontWeight: 400 }}>{rule.title}</div>
                </div>
                {favorites.rules && favorites.rules.length > 0 && favorites.rules.some(
                  (favoriteRule) => favoriteRule?.id === rule.rule_id,
                ) ? (
                  <FaBookmark
                    onClick={(e) => toggleRuleBookmark(e, rule)}
                  />
                  ) : (
                    <FaRegBookmark
                      className="initially-hidden"
                      onClick={(e) => toggleRuleBookmark(e, rule)}
                    />
                  )}
              </div>
              <TagList className="result-tags" tags={rule.tag_list} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
